import React from "react";
import Header from "../components/header";
import Main from "../components/main";
import Footer from "../components/Footer";
import WHATSAPP from '../assets/img/whatsapp.png'



export default function Home() {
    return(
        <div className="">
            <Header />
            <Main />
            <Footer />
        </div>
    )
}